import { memo, useCallback, useMemo, useState } from "react";
import { GoButton, TextTypo, Box, LobbyFooter } from "Game/Style/GameStyle";
import styled, { css } from "styled-components";
import { slideFadeUp } from "Game/Style/CSSAnimation";
import { useVonderGoContext } from "Service/VonderGOContext";
import { useLocalizationContext } from "Service/LocalizationProvider";
import { Select } from "antd";
import UpIcon from "Game/Assets/upIcon.svg";

const { Option } = Select;
export default memo(function Footer({
  isLobby,
  userInLobby,
  emptyUser,
  setStartSession,
  setPlayShadow,
}) {
  const {
    wsSend,
    animationState,
    dispatchAnimationState,
    playSFX,
    setTotalUsers,
    currentPhase,
    gameContextState,
    currentQuestionIndex,
    setCurrentQuestionIndex,
    setIsJumpQuestionIndexFromDropDown,
    isBotAccess,
  } = useVonderGoContext();
  const [isEmptyUser, setIsEmptyUser] = useState(false);
  const [isDisableStartButton, setIsDisableStartButton] = useState(false);

  const { t } = useLocalizationContext();

  const onClickNext = useCallback(() => {
    playSFX("major");
    wsSend(JSON.stringify({ event: "explanation-end" }));
  }, [playSFX, wsSend]);

  const closeQAPage = useCallback(() => {
    playSFX("major");
    dispatchAnimationState({ type: "show_qa", payload: false });
  }, [dispatchAnimationState, playSFX]);

  const onButtonStartSession = useCallback(() => {
    playSFX("major");
    const endUserList = userInLobby?.map((item) => item?.userID);
    if (emptyUser) {
      setIsEmptyUser(true);
      setTimeout(() => {
        setIsEmptyUser(false);
      }, 200);
      dispatchAnimationState({ type: "notification_visible", payload: true });
    } else {
      wsSend(
        JSON.stringify({ event: "session-pre-start", users: endUserList })
      );
      setTotalUsers(endUserList);
      setStartSession(true);
      setPlayShadow(true);
      setIsDisableStartButton(true);
    }
  }, [
    dispatchAnimationState,
    emptyUser,
    playSFX,
    setPlayShadow,
    setStartSession,
    setTotalUsers,
    userInLobby,
    wsSend,
  ]);

  const onChangeQuestionId = useCallback(
    (value) => {
      setCurrentQuestionIndex(value);
      setIsJumpQuestionIndexFromDropDown(true);
    },
    [setCurrentQuestionIndex, setIsJumpQuestionIndexFromDropDown]
  );

  const showButton = useMemo(() => {
    if (animationState?.showQA) {
      return (
        <GoButton size="small" type="primary" onClick={closeQAPage}>
          <TextTypo size="1.2vw" type="white">
            {t("BUTTON.CLOSE")}
          </TextTypo>
        </GoButton>
      );
    } else {
      return (
        <>
          {currentPhase === "explanation" && (
            <CustomSelect
              value={currentQuestionIndex}
              onChange={onChangeQuestionId}
              suffixIcon={
                <img src={UpIcon} alt="change-question-set" width="10vw" />
              }
              dropdownStyle={{ borderRadius: "6px" }}
            >
              {gameContextState?.allExplanation.map((item, index) => (
                <Option value={index} style={{ margin: "3%" }} key={index}>
                  <TextTypo
                  // size="1.2vw"
                  >
                    {t("GAMEPLAY.QUESTION")} {index + 1}
                  </TextTypo>
                </Option>
              ))}
            </CustomSelect>
          )}
          <GoButton size="small" type="primary" onClick={onClickNext}>
            <TextTypo size="1.2vw" type="white">
              {t("BUTTON.NEXT")}
            </TextTypo>
          </GoButton>
        </>
      );
    }
  }, [
    animationState?.showQA,
    closeQAPage,
    t,
    currentPhase,
    currentQuestionIndex,
    onChangeQuestionId,
    gameContextState?.allExplanation,
    onClickNext,
  ]);

  const checkFooter = useMemo(() => {
    if (isLobby) {
      return (
        <LobbyFooter justify="flex-end" align="center">
          {isBotAccess && (
            <GoButton
              style={{ marginRight: "12px" }}
              size="small"
              type="primary"
              onClick={() =>
                wsSend(
                  JSON.stringify({
                    event: "add-bots",
                    type: "controller",
                    totalBots: 10,
                  })
                )
              }
            >
              <TextTypo size="1.2vw" type="white">
                ADD BOTS
              </TextTypo>
            </GoButton>
          )}

          <GoButton
            size="small"
            type="primary"
            onClick={onButtonStartSession}
            empty={isEmptyUser?.toString()}
            disabled={isDisableStartButton}
          >
            <TextTypo size="1.2vw" type="white">
              {t("BUTTON.START")}
            </TextTypo>
          </GoButton>
        </LobbyFooter>
      );
    } else {
      return (
        <FooterBox
          visible={animationState?.footerVisible}
          justify={
            currentPhase === "explanation" ? "space-between" : "flex-end"
          }
          align="center"
        >
          {showButton}
        </FooterBox>
      );
    }
  }, [
    animationState?.footerVisible,
    currentPhase,
    isDisableStartButton,
    isEmptyUser,
    isLobby,
    onButtonStartSession,
    showButton,
    t,
    wsSend,
    isBotAccess,
  ]);

  return <>{checkFooter}</>;
});

const FooterBox = styled(Box)`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 10%;
  background-color: var(--white);
  box-shadow: 0px -1px 3px #00000029;
  padding: 0 3%;
  z-index: 3;
  ${(props) => {
    if (props.visible) {
      return css`
        opacity: 1;
        animation: 1s ${slideFadeUp};
      `;
    }
  }}
`;

const CustomSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 0.4vw !important;
  }
`;
